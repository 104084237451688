exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-nothanks-js": () => import("./../../../src/pages/nothanks.js" /* webpackChunkName: "component---src-pages-nothanks-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-pirate-explore-js": () => import("./../../../src/pages/pirate/explore.js" /* webpackChunkName: "component---src-pages-pirate-explore-js" */),
  "component---src-pages-pirate-favorites-js": () => import("./../../../src/pages/pirate/favorites.js" /* webpackChunkName: "component---src-pages-pirate-favorites-js" */),
  "component---src-pages-pirate-feeds-js": () => import("./../../../src/pages/pirate/feeds.js" /* webpackChunkName: "component---src-pages-pirate-feeds-js" */),
  "component---src-pages-pirate-index-js": () => import("./../../../src/pages/pirate/index.js" /* webpackChunkName: "component---src-pages-pirate-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-disclaimer-page-js": () => import("./../../../src/templates/disclaimer-page.js" /* webpackChunkName: "component---src-templates-disclaimer-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

